.layout-sider {

  &-logo {
    box-sizing: border-box;
    display: flex;
    color: @color-blue-5;
    display: flex;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    font-family: Microsoft YaHei;

    &-box {
      padding-bottom: 6px;
      display: flex;
      align-items: flex-end;
      line-height: 1;
      width: 100%;
    }

    &-img {
      height: 56px;
      margin-right: 8px;
    }


  }

  &-portal {
    margin: 14px 0 5px 20px;
    padding-left: 15px;
    border-left: 1px solid #2E3AEB;

    .portal-item {
      font-size: 14px;
      margin: 0 15px;
      cursor: pointer;

      &[data-active] {
        color: #2E3AEB;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          left: -4px;
          right: -4px;
          bottom: -6px;
          height: 2px;
          border-radius: 1px;
          background: #2E3AEB;
        }
      }
    }

    .portal-icon {
      font-size: 25px;
      margin-bottom: 4px;
    }
  }

  &-menu {
    margin-top: 55px;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 120px - 64px);

    &-button {
      margin: 16px auto !important;
      width: 150px !important;
    }

    &-button--small {
      margin: 16px auto !important;
      width: 60px !important;
      min-width: 60px;
    }
  }
}

.layout-section {

  &-header {
    box-sizing: border-box;
    height: 64px;
    padding: 10px 30px 10px 20px;
    display: flex;
    line-height: 1;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 1px 0px rgba(33, 34, 34, 0.08);
    z-index: 1;

    .header-left {}

    .header-right {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .header-right-item+.header-right-item {
        margin-left: 10px;
      }

      .config-icon {
        color: #7D7D7D;
        background: #ffffff !important;
        border: 1px solid #7D7D7D;
        cursor: pointer;
      }

      .user-info {
        color: @color-blue-5;
        cursor: pointer;
        vertical-align: middle;
      }

      .user-icon {
        margin-right: 10px;
      }

      .logout-icon {
        color: #D82007;
        background-color: #ffffff;
        border: none;
        cursor: pointer;
        font-size: 32px;
      }
    }
  }

  &-nav {
    padding: 14px 8px;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }

  &-content {
    margin: 0px 20px 0px;
    padding: 24;
  }
}